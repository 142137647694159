import http from '@/config/http-common';

class LocationDataService {
  static getCities(token: string|null): Promise<any> {
    return http.get('/location/cities', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default LocationDataService;
